(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/wapi-integration/assets/javascripts/wapi-integration.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/wapi-integration/assets/javascripts/wapi-integration.js');
"use strict";


const logger = svs.core.log.getLogger('oddset_kambi:components:wapiIntegration');
const navigateTo = async path => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.navigateClient(path);
  } catch (error) {
    logger.error('Kambi client navigation error: ', error);
  }
};
const showSportsbook = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.CLIENT_SHOW);
  } catch (error) {
    logger.error('Kambi client show sportsbook error: ', error);
  }
};
const hideSportsbook = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.CLIENT_HIDE);
  } catch (error) {
    logger.error('Kambi client hide sportsbook error: ', error);
  }
};
const clearBetslip = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.BETSLIP_CLEAR);
  } catch (error) {
    logger.error('Kambi clear betslip error: ', error);
  }
};
const hideBetslip = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.BETSLIP_HIDE);
  } catch (error) {
    logger.error('Kambi client hide betslip: ', error);
  }
};
const showBetslip = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.BETSLIP_SHOW);
  } catch (error) {
    logger.error('Kambi client show betslip: ', error);
  }
};
const PENDING_BETS_COUNT = 51;
const getPendingBets = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.request(wapi.BET_HISTORY, {
      count: PENDING_BETS_COUNT,
      offset: 0,
      status: wapi.FETCH_COUPON_STATUS.PENDING
    });
  } catch (error) {
    logger.error('Kambi client get pending bets: ', error);
  }
};
const setupWapiSubscription = async (_ref, wapi) => {
  let {
    setNumPendingBets
  } = _ref;
  try {
    const subscription = wapi.subscribe((response, wapi) => {
      switch (response.type) {
        case wapi.BET_HISTORY:
          {
            var _response$data$coupon, _response$data;
            const numPendingBets = (_response$data$coupon = (_response$data = response.data) === null || _response$data === void 0 || (_response$data = _response$data.coupons) === null || _response$data === void 0 ? void 0 : _response$data.length) !== null && _response$data$coupon !== void 0 ? _response$data$coupon : 0;
            setNumPendingBets(numPendingBets > PENDING_BETS_COUNT - 1 ? "".concat(numPendingBets - 1, "+") : numPendingBets);
            break;
          }
        default:
          break;
      }
    });
    return subscription;
  } catch (error) {
    logger.error('Kambi client setup subscription: ', error);
  }
};
setGlobal('svs.oddset_kambi.components.wapiIntegration', {
  showSportsbook,
  hideSportsbook,
  clearBetslip,
  navigateTo,
  hideBetslip,
  showBetslip,
  getPendingBets,
  setupWapiSubscription
});

 })(window);